.loader-pages {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // background: #383a3f;

  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.06);
  }
}

.loader-panel {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;

  .loader-panel-line {
    width: 0;
    background: #4285f4;
    height: 1.2px;
    transition: width 0.2s;
    animation-name: loaderLineAnimate;
    animation-duration: 0.8s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  .spinner-border {
    width: 1rem;
    height: 1rem;
    margin: 0.1rem;
    border-width: 0.15rem;
    color: #4285f4 !important;
  }
}

@keyframes loaderLineAnimate {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
